<template>
  <div class="containner" :class="is_mobile ? 'm-containner' : 'pc-containner'">
    <div class="pro-nav-bar" :class="nav_is_fixed ? 'pro-nav-bar-fixed' : ''">
      <img class="pro-logo-img" :src="pro_log" />
      <div class="pro-nav-wrap">
        <div class="pro-icon">
          <div class="pro-it-btn" :class="nav_act == 1 ? 'pro-it-btn-act' : ''" @click="scrollToSummarize">
            {{ $t("pro.takla.nav.summarize") }}
          </div>
          <div class="pro-it-btn" :class="nav_act == 2 ? 'pro-it-btn-act' : ''" @click="scrollToAdvantage">
            {{ $t("pro.takla.nav.advantage") }}
          </div>
          <div class="pro-it-btn" :class="nav_act == 3 ? 'pro-it-btn-act' : ''" @click="scrollToProperty">
            {{ $t("pro.takla.nav.property") }}
          </div>
        </div>
        <div class="pro-cooperation" @click="showCooparationForm">
          {{ $t("pro.takla.nav.coopearation") }}
        </div>
      </div>
    </div>
    <div class="banner" id="summarize">
      <img class="banner-bg" v-if="$t('language.shot_name') == 'cn'" :src="is_mobile ? banner_m : banner" />
      <img class="banner-bg" v-if="$t('language.shot_name') == 'en'" :src="is_mobile ? banner_m_en : banner_en" />
      <div class="pro-series-content">
        <div class="pro-series-name">{{ $t("pro.takla.name") }}</div>
        <div class="pro-series-desc">{{ $t("pro.takla.description") }}</div>
      </div>
    </div>
    <div class="performance-wrap">
      <img class="perform-bg" :src="is_mobile ? perform_bg_m : perform_bg" />
      <div class="perform-box">
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[0].icon" />
          <div class="perform-tit">{{ this.$t("pro.takla.perform_a") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[1].icon" />
          <div class="perform-tit">{{ this.$t("pro.takla.perform_b") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[2].icon" />
          <div class="perform-tit">{{ this.$t("pro.takla.perform_c") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[3].icon" />
          <div class="perform-tit">{{ this.$t("pro.takla.perform_d") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[4].icon" />
          <div class="perform-tit">{{ this.$t("pro.takla.perform_e") }}</div>
        </div>
      </div>
    </div>
    <div class="pro-advantage-box" id="advantage">
      <div class="pro-ad-list ani-tl-0">
        <cx-pro-advantage :bg="is_mobile ? advantage_list[0].bg_m : advantage_list[0].bg"
          :title="$t('pro.takla.advantage.adv_a_title')"
          :description="$t('pro.takla.advantage.adv_a_desc')"></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-1">
        <cx-pro-advantage :bg="is_mobile ? advantage_list[1].bg_m : advantage_list[1].bg"
          :title="$t('pro.takla.advantage.adv_b_title')"
          :description="$t('pro.takla.advantage.adv_b_desc')"></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-2">
        <cx-pro-advantage :bg="is_mobile ? advantage_list[2].bg_m : advantage_list[2].bg"
          :title="$t('pro.takla.advantage.adv_c_title')"
          :description="$t('pro.takla.advantage.adv_c_desc')"></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-3">
        <cx-pro-advantage :bg="is_mobile ? advantage_list[3].bg_m : advantage_list[3].bg"
          :title="$t('pro.takla.advantage.adv_d_title')"
          :description="$t('pro.takla.advantage.adv_d_desc')"></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-4">
        <cx-pro-advantage :bg="is_mobile ? advantage_list[4].bg_m : advantage_list[4].bg"
          :title="$t('pro.takla.advantage.adv_e_title')"
          :description="$t('pro.takla.advantage.adv_e_desc')"></cx-pro-advantage>
      </div>
    </div>
    <div class="takal-data-wrap" id="property">
      <img :src="pro_data_bg" class="takal-data-bg" />
      <div class="takal-data-core">
        <div class="takal-core-title">{{ $t("pro.takla.core_property.title") }}</div>
        <div class="takal-core-list">
          <div class="takal-core-key takal-core-key-4">
            {{ $t("pro.takla.core_property.camera_interface.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.camera_interface.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key takal-core-key-3">
            {{ $t("pro.takla.core_property.display_interface.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.display_interface.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key takal-core-key-3">
            {{ $t("pro.takla.core_property.sense_input.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.sense_input.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key takal-core-key-2">
            {{ $t("pro.takla.core_property.ethernet.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.ethernet.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key takal-core-key-2">
            {{ $t("pro.takla.core_property.can.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.can.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key">
            {{ $t("pro.takla.core_property.gpio.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.gpio.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key takal-core-key-2">
            {{ $t("pro.takla.core_property.arithmetic.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.arithmetic.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key">
            {{ $t("pro.takla.core_property.power_dissipation.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.power_dissipation.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key">
            {{ $t("pro.takla.core_property.ai_driving_level.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.ai_driving_level.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key">
            {{ $t("pro.takla.core_property.function_security.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.function_security.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key">
            {{ $t("pro.takla.core_property.info_security.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.info_security.value") }}
          </div>
        </div>
        <div class="takal-core-list">
          <div class="takal-core-key">
            {{ $t("pro.takla.core_property.volume.name") }}
          </div>
          <div class="takal-core-value">
            {{ $t("pro.takla.core_property.volume.value") }}
          </div>
        </div>
      </div>
    </div>
    <cx-contact @showCoopationForm="showCooparationForm"></cx-contact>
    <cx-cover-layout v-if="show_cooparation_form">
      <cx-cooperation source="Takla" @hideCooparationForm="hideCooparationForm"></cx-cooperation>
    </cx-cover-layout>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import Contact from "../../components/Contact.vue";
import ProAdvantage from "../../components/ProAdvantage.vue";
import Cooperation from "../../components/Cooperation.vue";
import CoverLayout from "../../components/CoverLayout.vue";

gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "RazorDCXTakla",
  components: {
    "cx-contact": Contact,
    "cx-pro-advantage": ProAdvantage,
    "cx-cooperation": Cooperation,
    "cx-cover-layout": CoverLayout,
  },
  props: {},
  data() {
    return {
      nav_act: 1,
      show_cooparation_form: false,

      pro_log: require("@/assets/pro-takla-logo.png"),
      pro_data_bg: require("@/assets/takla-data-bg.png"),

      banner: require("@/assets/takla-banner.png"),
      banner_en: require("@/assets/takla-banner-en.png"),
      banner_m: require("@/assets/takla-banner-m.png"),
      banner_m_en: require("@/assets/takla-banner-m-en.png"),
      series_case: require("@/assets/series-razordcx-takla.png"),
      perform_bg: require("@/assets/i-bg-a.png"),
      perform_bg_m: require("@/assets/series-bg.png"),

      advantage_list: [
        {
          bg: require("@/assets/takla-det-a.png"),
          bg_m: require("@/assets/takla-det-a-m.png"),
        },
        {
          bg: require("@/assets/takla-det-b.png"),
          bg_m: require("@/assets/takla-det-b-m.png"),
        },
        {
          bg: require("@/assets/takla-det-c.png"),
          bg_m: require("@/assets/takla-det-c-m.png"),
        },
        {
          bg: require("@/assets/takla-det-d.png"),
          bg_m: require("@/assets/takla-det-d-m.png"),
        },
        {
          bg: require("@/assets/takla-det-e.png"),
          bg_m: require("@/assets/takla-det-e-m.png"),
        },
      ],

      perform_list: [
        {
          icon: require("@/assets/takla-icon-a.png"),
        },
        {
          icon: require("@/assets/takla-icon-b.png"),
        },
        {
          icon: require("@/assets/takla-icon-c.png"),
        },
        {
          icon: require("@/assets/takla-icon-d.png"),
        },
        {
          icon: require("@/assets/takla-icon-e.png"),
        },
      ],

      nav_is_fixed: false,
      tl: null,
      tl3: null,
    };
  },
  methods: {
    hideCooparationForm() {
      this.show_cooparation_form = false;
    },
    showCooparationForm() {
      this.show_cooparation_form = true;
    },
    scrollToSummarize() {
      let off_set = 30;
      if (this.is_mobile) {
        off_set = 50;
      }
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#summarize", offsetY: off_set } });
      this.nav_act = 1;
    },
    scrollToAdvantage() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#advantage", offsetY: 100 } });
      this.nav_act = 2;
    },
    scrollToProperty() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#property", offsetY: 65 } });
      this.nav_act = 3;
    },
  },
  destroyed() {
    this.tl.kill();
    this.tl3.kill();
    this.tl = null;
    this.tl3 = null;
  },
  mounted() {
    let that = this;

    this.tl = gsap.timeline({});
    this.tl
      .addLabel("start")
      .from(".banner-bg", {
        opacity: 0,
        x: 100,
      })
      .from(".pro-series-name", {
        opacity: 0,
        y: 50,
      })
      .from(".pro-series-desc", {
        opacity: 0,
        y: 50,
      })
      .from(".pro-series-btn", {
        opacity: 0,
        y: 50,
      })
      .from(
        ".perform-list",
        {
          opacity: 0,
          duration: 0.5,
          y: 50,
        },
        "<"
      )
      .from(".perform-tit", {
        opacity: 0,
        delay: -0.3,
        y: 50,
      });

    let tl3_start = 100;

    this.tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".pro-advantage-box",
        pin: true,
        start: "-" + tl3_start + " top",
        end: "+=1000",
        scrub: 0,
      },
    });

    let trans_scale = this.rate * 650;
    this.tl3
      .addLabel("begin")
      .to(".ani-tl-0", { opacity: 1, y: 0, scale: 0.95 })
      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 1 }, "<")

      .to(".ani-tl-0", { opacity: 1, y: 0, scale: 0.85 })
      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1, scale: 0.95 }, "<")
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 2 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 2 }, "<")

      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1, scale: 0.85 })
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2, scale: 0.95 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 3 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 3 }, "<")

      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2, scale: 0.85 })
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 3, scale: 0.95 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 4 }, "<");

    window.addEventListener("scroll", function () {
      const scroll_top = that.getScrollTop();
      if (scroll_top < that.rate * 100) {
        that.nav_is_fixed = false;
      } else {
        that.nav_is_fixed = true;
      }
      if (!that.is_mobile) {
        if (scroll_top < that.rate * 1140) {
          that.nav_act = 1;
        } else if (scroll_top >= that.rate * 1140 && scroll_top <= that.rate * 3350) {
          that.nav_act = 2;
        } else if (scroll_top >= that.rate * 3350) {
          that.nav_act = 3;
        }
      } else {
        if (scroll_top < that.rate * 1940) {
          that.nav_act = 1;
        } else if (scroll_top >= that.rate * 1940 && scroll_top <= that.rate * 4620) {
          that.nav_act = 2;
        } else if (scroll_top >= that.rate * 4620) {
          that.nav_act = 3;
        }
      }
    });
  },
};
</script>
<style lang="less" scoped>
.pro-advantage-box {
  margin: 120px 0px 0px;
  height: 700px;
}

.pro-nav-bar {
  position: absolute;
  top: 0px;
  height: 86px;
  width: 100%;
  z-index: 111;
  background-color: rgba(36, 36, 36, 1);
}

.pro-nav-bar-fixed {
  position: fixed;
  top: 0px;
}

.pro-ad-list {
  position: relative;
  width: 1300px;
  height: 650px;
  margin: 0 auto;
}

.ani-tl-0 {
  z-index: 101;
}

.ani-tl-1 {
  z-index: 102;
}

.ani-tl-2 {
  z-index: 103;
}

.ani-tl-3 {
  z-index: 104;
}

.ani-tl-4 {
  z-index: 105;
}

.ani-tl-5 {
  z-index: 106;
}

.pro-logo-img {
  position: absolute;
  height: 84px;
  left: 223px;
}

.pro-nav-wrap {
  position: absolute;
  right: 230px;
  top: 26px;
}

.pro-it-btn {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  display: inline-block;
  color: #fff;
  margin-left: 90px;
  border-bottom: 1px solid rgba(36, 36, 36, 1);
  transition: 300ms linear all;
  cursor: pointer;
  // box-shadow: 0px 2px 4px 0px rgba(0, 183, 255, 1);
}

// .pro-it-btn:hover {
//   border-bottom: 1px solid rgba(51, 146, 255, 1);
// }
.pro-it-btn-act {
  border-bottom: 1px solid rgba(51, 146, 255, 1);
}

.pro-cooperation {
  display: inline-block;
  padding: 0px 10px;
  height: 35px;
  line-height: 35px;
  opacity: 1;
  font-size: 16px;
  margin-left: 90px;
  font-weight: 400;
  height: 35px;
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
  border-radius: 3.19px;
  background: rgba(144, 218, 252, 1);
}

.pro-icon {
  display: inline-block;
}

.containner {
  padding-top: 86px;
  position: relative;
  overflow: hidden;
}

.banner {
  position: relative;
  height: 587px;
}

.banner-bg {
  position: absolute;
  width: 1920px;
  height: 587px;
  z-index: 0;
  left: 0px;
  top: 0px;
}

.pro-series-content {
  position: absolute;
  color: #fff;
  z-index: 11;
  top: 103px;
  left: 230px;
}

.pro-series-name {
  font-size: 44px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 29px;
}

.pro-series-desc {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  width: 700px;
  text-align: left;
}

.performance-wrap {
  position: relative;
  height: 530px;
  overflow: hidden;
}

.perform-bg {
  position: absolute;
  z-index: 0;
  left: 0px;
  bottom: 0px;
  width: 1920px;
  height: 709px;
}

.perform-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 11;
}

.perform-list {
  margin-top: 152px;
  padding: 0px 50px;
}

.perform-icon {
  width: 101px;
  height: 101px;
}

.perform-tit {
  text-align: center;
  width: 184px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  margin-top: 21px;
}

.takal-data-wrap {
  position: relative;
  width: 1920px;
  height: 1194px;
}

.takal-data-bg {
  position: absolute;
  width: 1920px;
  height: 1194px;
  z-index: 0;
  left: 0px;
  bottom: 0px;

  overflow: hidden;
}

.takal-data-core {
  position: relative;
  width: 850px;
  top: 120px;
  margin: 0 auto;
  z-index: 11;
}

.takal-core-title {
  font-size: 44px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.takal-core-list {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  padding: 15px 0px;
}

.takal-core-key {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-left: 106px;
  vertical-align: middle;
  width: 220px;
  float: left;
  text-align: left;
}

.takal-core-key-2 {
  margin-top: 20px;
}

.takal-core-key-3 {
  margin-top: 30px;
}

.takal-core-key-4 {
  margin-top: 40px;
}

.takal-core-value {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  white-space: pre-wrap;
  float: left;
  text-align: left;
}

.m-containner {
  padding-top: 180px;

  .banner {
    position: relative;
    height: auto;
    overflow: hidden;
    height: 1118px;

    .banner-bg {
      width: 750px;
      height: 1118px;
    }

    .pro-series-content {
      width: 100%;
      text-align: center;
      left: 0px;

      .pro-series-name {
        width: 620px;
        font-size: 50px;
        margin: 0 auto;
        margin-bottom: 30px;
      }

      .pro-series-desc {
        width: 620px;
        font-size: 25px;
        margin: 0 auto;
      }
    }
  }

  .performance-wrap {
    width: 750px;
    overflow: hidden;
    height: 634px;
    padding-top: 40px;

    .perform-bg {
      width: 750px;
      height: 674px;
    }

    .perform-box {
      padding-top: 40px;

      .perform-list {
        padding: 10px;
        margin-top: 60px;
      }
    }
  }

  .takal-core-value {
    width: 500px;
  }

  .takal-core-key {
    width: 160px;
    margin-left: 40px;
  }

  .pro-nav-bar {
    height: 180px;

    .pro-logo-img {
      left: 35px;
    }

    .pro-cooperation {
      width: 130px;
      height: 45px;
      line-height: 45px;
      font-size: 24px;
      text-align: center;
    }

    .pro-nav-wrap {
      width: 100%;
      right: 23px;
      text-align: right;

      .pro-icon {
        position: absolute;
        width: 700px;
        left: 0px;
        text-align: left;
        top: 65px;
        font-size: 24px;

        .pro-it-btn {
          font-size: 27px;
          padding: 10px 0px;
        }
      }
    }
  }

  .pro-advantage-box {
    height: 750px;

    .pro-ad-list {
      width: 750px;
    }
  }

  .takal-data-wrap {
    width: 750px;
  }

  .takal-data-core {
    top: 70px;
  }
}</style>
